import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Source+Code+Pro:wght@400;600&display=swap');

:root {
    --primary-color: #EF761F;
    --secondary-color: #0f151b;
    --white-color: #f9f9f9;
    --black-color: #0F151B;
    --blue-color: #6C99B9;
    --background-color: #11161c;
    --primary-color-rgb: 235, 142, 33;
    --secondary-color-rgb: 15, 21, 27;
    --white-color-rgb: 255, 255, 255;
    --black-color-rgb: 0, 0, 0;
    --background-color-rgb: 17, 22, 28;
    --primary-font: 'Manrope', sans-serif;
    --secondary-font: 'Roboto', sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    background: white;
    font-family: Manrope, Source Code Pro, Sans-Serif;
    width: 100%;
    height: 100%;
  }
  body::-webkit-scrollbar {
    display: none;
  }
  .MuiButton-root {
    border-radius: 4px;
  }

`