/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { useMediaQuery } from '@react-hook/media-query'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Header from '../header'
import { TABLET_PORTRAIT_DEVICE } from '../common/breakpoints'
import HomeSVG from '../../images/icons/nav/home.svg'
import ServicesSVG from '../../images/icons/nav/monitor.svg'
import AboutSVG from '../../images/icons/nav/cpu.svg'
import ResourcesSVG from '../../images/icons/nav/archive.svg'
import CarrersSVG from '../../images/icons/nav/briefcase.svg'
import PartnersSVG from '../../images/icons/nav/share-2.svg'
import TeamsSVG from '../../images/icons/nav/users.svg'
import SolutionsSVG from '../../images/icons/nav/layout.svg'
import FacebookSVG from '../../images/icons/social-media/facebook.svg'
import InstagramSVG from '../../images/icons/social-media/instagram.svg'
import LinkedinSVG from '../../images/icons/social-media/linkedin.svg'
import TwitterSVG from '../../images/icons/social-media/twitter.svg'
import { PRIMARY_COLOR, BLACK_COLOR } from '../common/color'
import { GlobalStyle } from './globalStyle'
import bp from '../common/breakpoints'
import { css, jsx } from '@emotion/react'

const SuperContainer = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Manrope' !important;
  position: relative;
  padding-right: 0;
  height: 100%;
  width: 100%;
  background: #ededed;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    z-index: 999;
    display: none;
  }
`
const Container = styled.div`
  display: grid;
  row-gap: 1rem;
  height: 100%;
  grid-template-rows: 10vh 1fr;
  grid-template-columns: 1fr;
  width: 90%;
  max-width: 1480px;
  ${bp[3]} {
    column-gap: 2rem;
    grid-template-rows: 8vh 1fr;
    grid-template-columns: 14vh 1fr;
  }
  ${bp[4]} {
  }
`
const LayoutTop = styled.div`
  z-index: 1;
  grid-column: 1/3;
  //width: 100%;
  grid-row: 1/2;
  position: fixed;
  top: 0;
  justify-content: center;
  left: 0;
  background: #ededed;
  min-height: 10vh;
  width: 100%;
  z-index: 999;
  ${bp[3]} {
    grid-column: 1/3;
    grid-row: 1/2;
    z-index: 999;
  }
  // border-bottom: 1px solid #9C9C9C;
`
const LayoutLeft = styled.div`
  grid-column: 1/2;
  grid-row: 2/3;
  display: none;
  height: 100%;
  position: fixed;
  flex-direction: column;
  justify-content: flex-end;
  ${bp[3]} {
    display: flex;
  }
`
const SocialButtonsContainer = styled.div`
  max-height: 200px;
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3rem;
`
const SocialButton = styled.div`
  color: ${BLACK_COLOR};
  &:hover {
    color: ${PRIMARY_COLOR};
    opacity: 1;
    cursor: pointer;
  }
`
const LayoutContent = styled.div`
  grid-column: 1/3;
  grid-row: 2/3;
  height: 100%;
  padding-top: 15px;
  @media (min-width: 1280px) {
    grid-column: 2/3;
    height: 100%;
  }
  ${bp[4]} {
    padding-top: 75px;
  }
`

const SocialButtons = [
  {
    title: 'Instagram',
    to: 'https://www.instagram.com/alioit_solutions/',
    icon: <InstagramSVG />,
  },
  {
    title: 'Facebook',
    to: 'https://www.facebook.com/alioits',
    icon: <FacebookSVG />,
  },
  {
    title: 'Linkedin',
    to: 'https://www.linkedin.com/company/alio-nearshore-it',
    icon: <LinkedinSVG />,
  },
  {
    title: 'Twitter',
    to: 'https://www.twitter.com/alio_it',
    icon: <TwitterSVG />,
  },
]

const AppLayout = ({ children }) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const matches = useMediaQuery(`(min-width: ${TABLET_PORTRAIT_DEVICE}px)`)
  useEffect(() => {
    if (matches) {
      setOpenMobileMenu(true)
    } else {
      setOpenMobileMenu(false)
    }
  }, [matches])

  return (
    <>
      <GlobalStyle />
      <SuperContainer>
        <Container>
          <LayoutTop>
            <Header
              toggleMenu={setOpenMobileMenu}
              isMenuOpen={openMobileMenu}
            />
          </LayoutTop>
          <LayoutLeft>
            <SocialButtonsContainer>
              {SocialButtons.map((item, index) => (
                <SocialButton
                  key={item.title}
                  to={item.to}
                  onClick={() => window.location.assign(item.to)}
                >
                  {item.icon}
                </SocialButton>
              ))}
            </SocialButtonsContainer>
          </LayoutLeft>
          <LayoutContent>{children}</LayoutContent>
        </Container>
      </SuperContainer>
    </>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppLayout
